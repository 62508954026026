.languages {
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.languages__container {

    max-width: 800px;
    margin: 0 auto;
}

.languages__heading {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
}

.languages__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.languages__item {
    font-size: 1.1rem;
    color: #555;
    padding: 0.5rem 0;
    border-bottom: 1px solid #ddd;
}

.languages__item span {
    font-weight: 600;
}

.languages__item:last-child {
    border-bottom: none;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2rem; /* Adjust margin as needed */
}

.button-2 {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-2:hover {
    background-color: #0056b3;
}
