.about-me {
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .about-me__container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .about-me__heading {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .about-me__text {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
  }
  