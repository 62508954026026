.education {
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .education__container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .education__heading {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .education__list {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .education__list > div {
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .education__list h3 {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .education__list p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 0.5rem;
  }
  