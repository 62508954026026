.certifications {
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .certifications__container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .certifications__heading {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .certifications__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .certifications__item {
    font-size: 1.1rem;
    color: #555;
    padding: 0.5rem 0;
    border-bottom: 1px solid #ddd;
  }
  
  .certifications__item:last-child {
    border-bottom: none;
  }
  