.work-experience {
    padding: 2rem;
    background-color: #f1f1f1;
    border-radius: 8px;
  }
  
  .work-experience__container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .work-experience__heading {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .work-experience__item {
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 1.5rem;
  }
  
  .work-experience__item h3 {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .work-experience__item p {
    font-size: 1rem;
    color: #555;
  }
  