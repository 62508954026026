.personal-projects {
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.personal-projects__container {
  max-width: 800px;
  margin: 0 auto;
}

.personal-projects__heading {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.personal-projects__text {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  margin-bottom: 2rem;
  text-align: center;
}

.personal-projects__list {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.personal-projects__item {
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: left;
}

.personal-projects__item h3 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.personal-projects__item p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

.button {
  padding: 0.5rem 1rem;
  background-color: #4f46e5;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 0.5rem;
  text-decoration: none;
  font-size: 0.875rem;
}

.button:hover {
  background-color: #4338ca;
}

.button[aria-label="View Prototype"] {
  background-color: #10b981;
}

.button[aria-label="View Prototype"]:hover {
  background-color: #059669;
}
